import { Alert } from './alert';
import { BroadcastChannel } from './broadcast-channel';
import {
  IncidentSchema,
  IncidentSchemaImpact,
  IncidentSchemaPriority,
  IncidentSchemaState,
  IncidentSchemaVersion,
} from './incident-schema';
import { OrganizationMemberMetadata } from './organization';
import { Service } from './service';
import { Statuspage } from './statuspage';
import { Task, TaskStatus } from './task';

export interface Incident {
  id: number;
  schemaVersion: IncidentSchemaVersion;
  title: string;
  summary: string;
  priority: IncidentSchemaPriority;
  state: IncidentSchemaState;
  createdAt: Date;
  affectedServices?: AffectedService[];
  statuspageLinks?: IncidentStatuspageLink[];
  roles?: IncidentRoleWithAssignment[];
  events?: {
    events: IncidentEvent[];
    totalSize: number;
    nextPageToken: string;
  };
  timings: IncidentTimings;
  broadcastMessages?: BroadcastMessage[];
  tasks: {
    linkId: string;
    taskId: string;
  }[];
}

export interface IncidentRelatedAlertList {
  alerts: Alert[];
  totalSize: number;
}

export interface IncidentSpec {
  schemaVersionId: string;
  priorityId: string;
  title: string;
  summary: string;
  affectedServiceIds: number[];
  statuspageIds: number[];
}

export interface AffectedService {
  service: Service;
  impact: IncidentSchemaImpact;
}

export interface IncidentStatuspageLink {
  statuspage: {
    id: number;
    name: string;
    customDomain?: string;
    domain: string;
    slug: string;
  };
}

export interface IncidentRoleWithAssignment {
  role: IncidentRole;
  assignments: IncidentRoleAssignment[];
}

export interface IncidentRoleAssignment {
  id: string;
  memberId: string;
  user?: {
    firstname: string;
    lastname: string;
    avatar: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export interface IncidentTimings {
  impactStarted?: Date;
  impactAcknowledged?: Date;
  impactResolved?: Date;
}

export enum IncidentEventActor {
  SYSTEM = 'system',
  USER = 'user',
}

export interface IncidentEvent {
  id: string;
  type: string;
  payload:
    | IncidentEventPayloadRole
    | IncidentEventPayloadFieldUpdated
    | IncidentEventPayloadComment
    | IncidentEventPayloadAffectedService
    | IncidentEventPayloadStatuspage
    | IncidentEventPayloadTask
    | IncidentEventPayloadTaskStatus
    | IncidentEventPayloadTaskAssigned
    | IncidentEventPayloadEmpty;
  createdAt: Date;
  actor: {
    type: IncidentEventActor;
    user?: {
      firstname: string;
      lastname: string;
      avatar: string;
    };
    name?: string;
  };
}

export interface IncidentEventPayloadRole {
  role: IncidentRole;
  assignee: {
    firstname: string;
    lastname: string;
    avatar: string;
  };
}

export interface IncidentEventPayloadResponder {
  responder: {
    firstname: string;
    lastname: string;
    avatar: string;
  };
}

export interface IncidentEventPayloadFieldUpdated {
  field: string;
  oldValue: FieldValue;
  newValue: FieldValue;
}

export interface IncidentEventPayloadStateUpdated {
  oldState: IncidentSchemaState;
  newState: IncidentSchemaState;
}

export interface IncidentEventPayloadPriorityUpdated {
  oldPriority: IncidentSchemaState;
  newPriority: IncidentSchemaState;
}

export interface FieldValue {
  stringValue?: string;
  intValue?: number;
}

export interface IncidentEventPayloadComment {
  comment: string;
}

export interface IncidentEventPayloadAffectedService {
  service: Service;
}

export interface IncidentEventPayloadAffectedServiceImpactChanged {
  service: Service;
  oldImpact: IncidentSchema;
  newImpact: IncidentSchema;
}

export interface IncidentEventPayloadStatuspage {
  statuspage: Statuspage;
}

export interface IncidentEventPayloadRelatedAlert {
  alert: Alert;
}

export interface IncidentEventPayloadTask {
  task: Pick<Task, 'id' | 'name' | 'description'>;
}

export interface IncidentEventPayloadTaskStatus {
  task: Pick<Task, 'id' | 'name' | 'description'>;
  oldStatus: TaskStatus;
  newStatus: TaskStatus;
}

export interface IncidentEventPayloadTaskAssigned {
  task: Pick<Task, 'id' | 'name' | 'description'>;
  assignee: Pick<
    OrganizationMemberMetadata,
    'firstname' | 'lastname' | 'avatar'
  >;
}

export type IncidentEventPayloadEmpty = object;

export interface IncidentRole {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface BroadcastMessage {
  id: string;
  channel: BroadcastChannel;
}

export interface IncidentTaskLink {
  linkId: string;
  task: Task;
}
